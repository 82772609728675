<template>
    <div v-if="alertOn" class="alert alert-danger alert-dismissible w-75" role="alert" id="red">
        {{ message }}
        <button @click="close" type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <!-- <div class="w-100 d-flex justify-content-center">
        <div class="alert alert-danger mt-4 w-75" role="alert" style="z-index: 4;">
            {{ message }}
        </div>
    </div> -->
</template>


<script>


export default {
    name: "DangerAlert",
    data() {
        return {
            alertOn: true
        };
    },
    props: {
        message: {
            type: String,
            required: true
        },
        close:{
            type: Function,
            required: true
        }
    },
}
</script>