import { createRouter, createWebHistory } from 'vue-router';
import UserLogin from '../components/Login.vue'
import UserSignup from '../components/Signup.vue'
import HomePage from '../components/Home.vue'
import AddUserCampaign from '../components/Addcampaign.vue'
import CampaignDetail from '../components/Detail.vue'


const routes = [
  {
    path: '/',
    name: 'Login',
    component: UserLogin,
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: UserSignup,
  },
  {
    path: '/Home',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/AddCampaign',
    name: 'AddCampaign',
    component: AddUserCampaign,
  },
  {
    path: '/CampaignDetail',
    name: 'CampaignDetail',
    component: CampaignDetail,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
