<template>
 <div id="app">
    <nav>
      <router-link to="/"></router-link>
      <router-link to="/signup"></router-link>
    </nav>
    <router-view /> <!-- This is where the routed components will appear -->
  </div>
</template>

<script>




export default {
  name: 'App',

}
</script>


