<template>
    <div class="d-flex align-items-center justify-content-center table-loading-com">
        <div class="spinner-border text-primary" role="status">
        </div>
    </div>
</template>


<script>

export default {
    name: 'TableLoadingCheck',
}
</script>