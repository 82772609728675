<template>
    <div class="Page-outline">
        <div class="d-flex align-items-center flex-column justify-content-center min-vh-100 w-100">
            <DangerAlert v-if="isDanger == true" :close="closeAlert" :message="alertMsg" />
            <SucessAlert v-if="isSucess == true" :close="closeSucess" :message="alertMsg" />
            <div class="shadow-lg d-flex min-vh-50 h-auto w-25 form-div ">
                <form class="d-flex align-items-center p-3 flex-column w-100">
                    <h2 class="pt-2 mb-3">Sign Up</h2>
                    <div class="form-group w-100 mt-2">
                        <label>Email address</label>
                        <input type="email" v-model="email" class="form-control shadow" id="exampleInputEmail1" aria-describedby="emailHelp"
                            placeholder="Enter email">
                    </div>
                    <div class="form-group w-100 mt-2">
                        <label>Password</label>
                        <input type="password" v-model="password" class="form-control shadow" id="exampleInputPassword1" placeholder="Password">
                    </div>
                    <div class="d-flex w-100 ml-1">
                        <button type="button" class="btn btn-primary btn-block mt-3" @click="createUser">Sign Up</button>
                    </div>
                    <div class="form-check mt-3">
                        <router-link to="/" class="form-check-label">Already Have Account?</router-link>
                    </div>
                </form>

            </div>
        </div>
    </div>
    <LoadingCheck v-if="isLoading == true" />

</template>


<script>
import axios from 'axios';
import LoadingCheck from "./Loading.vue"
import DangerAlert from "./Danger.vue";
import SucessAlert from "./Sucess.vue"

export default {
  name: 'UserSignup',
  data() {
        return {
            email: '',
            password: '',
            isLoading: false,
            isDanger: false,
            isSucess: false,
            alertMsg: ''
        }
    },
  components: {
        LoadingCheck,
        DangerAlert,
        SucessAlert
    },
  methods: {
    async createUser(){
        this.isLoading = true
        const obj = {
            username: this.email,
            password: this.password,
        };
        try {
            await axios.post(`/api/v1/users`, obj,{
                headers:{
                    'Content-Type' : 'application/json'
                }
            })
            this.isLoading = false
            this.$router.push({ name: 'Login' });     
        } catch (error) {
            if (error.response) {
                    const statusCode = error.response.status;
                    if (statusCode === 404) {
                        this.alertMsg = "User not found (404)"
                    } else if (statusCode === 400) {
                        this.alertMsg = "User Already Exist"

                    } else if (statusCode === 500) {
                        this.alertMsg = "Server error (500)"
                    } else if(statusCode === 401){
                        this.$router.push({ name: 'Login' });        
                    } else {
                        this.alertMsg = `Error: ${statusCode}`
                    }
                } else if (error.request) {
                    this.alertMsg = "No response received from server"

                } else {
                    this.alertMsg = `Request setup error ${error.message}`
                }
 
            this.isDanger = true
            this.isLoading = false
        }


    },
    closeAlert() {
        this.isDanger = false
    },
    closeSucess() {
        this.isSucess = false
    }
  }
}
</script>