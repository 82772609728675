<template>
    <div v-if="athentication == true" class="d-flex flex-column align-items-center min-vh-100 Page-outline">
        <header>
            <div class="d-flex align-items-end h-100 heading-div">
                <router-link to="/Home" class="bi bi-arrow-left back-icon">

                </router-link>
            </div>
        </header>
        <DangerAlert :close="closeAlert" v-if="danger" :message="message" />
        <SucessAlert :close="closeSucess" v-if="sucess" :message="message" />
        <main class="container-fluid d-flex flex-column align-items-center mt-2 w-87 detail-main">
            <div class="w-100 mb-3 font-weight-bold detail-heading">
                <h1 class="d-flex align-items-center w-50 min-vh-6 mb-3 detail-heading-part">
                    {{ campaignData.name ? campaignData.name : 'Loading...' }}: {{ campaignData.status }}
                </h1>
                <div class="d-flex flex-row w-50 mt-2 ml-4 h-100">
                    <div class="d-flex align-items-center  mr-1 h-100 pen-container">
                        <router-link :to="{ path: '/AddCampaign', query: { update: true, id: encodeId } }"
                            class="bi bi-pen ml-2 pen-icon"></router-link>
                        <!-- <router-link :to="{ path: '/AddCampaign', query: { update: true, id: encodeId } } " @click="UpdateDataLocalstorage()"
                            class="btn btn-primary ml-1 mb-1 btn-">
                            <i class="bi bi-plus">
                                <span class="add-btn-font-style">Add New</span>
                            </i>
                        </router-link> -->
                    </div>
                    <div v-if="campaignData.status === 'PENDING'"
                        @click="updatePlayPause()" class="d-flex align-items-center  mr-3 h-100 pen-container">
                        <button class="btn btn-primary ml-1 mb-1 btn-sm">Start</button>
                    </div>
                    <div v-if="campaignData.status === 'PAUSED'"
                        @click="updatePlayPause()" class="d-flex align-items-center  mr-3 h-100 pen-container">
                        <button class="btn btn-primary ml-1 mb-1 btn-sm">Resume</button>
                    </div>
                    <div v-if="campaignData.status == 'IN_PROGRESS'"
                        class="d-flex align-items-center  mr-3 h-100 pen-container">
                        <button class="btn btn-primary ml-1 mb-1 btn-sm" @click="updatePlayPause()">Pause</button>
                    </div>
                    <div v-if="campaignData.status !== 'PENDING' && campaignData.status !== 'COMPLETE'"
                        class="d-flex align-items-center w-12 mr-2 h-100 pen-container">
                        <button class="btn btn-danger ml-1 mb-1 btn-sm" @click="interruptCampaign()">Interrupt</button>
                    </div>
                    <div v-if="campaignData.status === 'PENDING'"
                        class="d-flex align-items-center w-12 mr-2 h-100 pen-container">
                        <button class="btn btn-danger ml-1 mb-1 btn-sm" @click="deleteCampaign()">Delete</button>
                    </div>
                    <div v-if="campaignData.status === 'COMPLETE'" @click="downloadResult()"
                        class="d-flex align-items-center  mr-3 h-100 pen-container">
                        <button class="btn btn-success ml-1 mb-1 btn-sm">Results</button>
                    </div>
                    <div v-if="campaignData.status === 'COMPLETE'" @click="restartFailedUrls()"
                        class="d-flex align-items-center  mr-3 h-100 pen-container">
                        <button class="btn btn-danger ml-1 mb-1 btn-sm">Restart Urls</button>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row align-items-left w-100 mb-4">
                <!-- <h2 class="d-flex justify-content-center align-items-center w-100 min-vh-2 detail-heading-scraped"> -->
                    <strong>Total Results:</strong>&nbsp;{{ results }}
                <!-- </h2> -->
            </div>
            <div class="d-flex flex-row align-items-left w-100 mb-4">
                <!-- <p class="d-flex w-100 min-vh-4"> -->
                    <strong>Type:</strong>&nbsp;{{ campaignData.campaign_type }}
                <!-- </p> -->
            </div>
            <div class="d-flex flex-row align-items-left w-100 mb-4">
                <!-- <p class="d-flex w-100 min-vh-4"> -->
                    <strong>Start At:</strong>&nbsp;{{ campaignData.start_by === 'MANUAL' ? 'manually' : (new Date(campaignData.start_at)).toLocaleString() }}
                <!-- </p> -->
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center w-100 mb-4">
                <h2 class="d-flex justify-content-center align-items-center w-100 min-vh-2 detail-heading-scraped">
                    Tasks Info
                </h2>
            </div>
            <div class='custom-url-table-main'>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Total</th>
                            <th>Queued</th>
                            <th>In Progress</th>
                            <th>Complete</th>
                            <th>Failed</th>
                            <th v-if="interrupted > 0">Interrupted</th>
                        </tr>
                    </thead>
                    <tbody v-if="isLoading == false">
                        <tr>
                            <td>{{ totalTasks }}</td>
                            <td>{{ queued }}</td>
                            <td>{{ inProgress }}</td>
                            <td>{{ complete }}</td>
                            <td>
                                {{ failed }}
                                <button v-if="failedTable === false" @click="getFailedUrls()" class="btn btn-primary ml-2 btn-sm">View</button>
                                <button v-else @click="hideFailedUrls()" class="btn btn-primary ml-2 btn-sm">Hide</button>
                            </td>
                            <td  v-if="interrupted > 0">{{ interrupted }}</td>
                        </tr>
                    </tbody>
                    <tbody v-if="isLoading == true" class="d-flex jus">
                        <TableLoading />
                    </tbody>
                </table>
            </div>
            <div v-if="failedTable" class='custom-url-table-main'>
                <table class="table">
                    <thead>
                        <tr>
                            <th>id</th>
                            <th>url</th>
                            <th>Failure reason</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="isSecondTable == false" id="tbl-fails-details">
                        <tr v-for="(obj, index) in failedUrls" :key="index">
                            <td v-if="obj.status == 'FAILED'">{{ obj.id }}</td>
                            <td v-if="obj.status == 'FAILED'" class="long-link-column"><a :href="obj.url" target="_blank">{{ obj.url }}</a></td>
                            <td v-if="obj.status == 'FAILED'"><span class="failure-details" @click="showFailureReasonMessage(obj.failure_reason)">{{ obj.failure_reason }}</span></td>
                            <td v-if="obj.status == 'FAILED'">
                                <button class="btn btn-danger ml-1 mb-1 btn-sm"
                                    @click="deleteUrl(obj.id)">Delete</button>
                                <button class="btn btn-success ml-1 mb-1 btn-sm" @click="markAsComplete(obj.id)">Mark as
                                    Complete
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-if="isSecondTable == true">
                        <TableLoading />
                    </tbody>
                </table>
                <div v-if="selectedFailureReason" id="fail-reason-message-wrapper">
                    <div id="failure-reason-message">
                        <p>{{ selectedFailureReason }}</p>
                        <div class="text-center">
                            <button class="btn btn-danger" @click="hideFailureReasonMessage()">Close</button>
                        </div>
                    </div>
                </div>
                <!-- <div class="d-flex align-items-center justify-content-center load-more-btn">
                    <button v-if="limit <= tolFailedUrls" @click="getFailedUrls()" type="button"
                        class="btn btn-outline-primary btn-lg">
                        Load More
                    </button>
                </div> -->
            </div>
        </main>
        <LoadingCheck v-if="isInterupt == true"></LoadingCheck>
    </div>
</template>


<script>
import axios from 'axios';
import { encode } from '@yag/id-hash';
import { decode } from '@yag/id-hash';
import LoadingCheck from "./Loading.vue"
import TableLoading from './TableLoading.vue';
import DangerAlert from "./Danger.vue";
import SucessAlert from "./Sucess.vue"

export default {
    name: 'CampaignDetail',
    data() {
        return {
            campaignId: null,
            campaignData: {},
            failedTable: false,
            failedUrls: [],
            encodeId: null,
            isLoading: true,
            isSecondTable: false,
            isInterupt: false,
            danger: false,
            sucess: false,
            message: '',
            StatusCode: 1,  // For Testing
            token: null,
            totalTasks: 0,
            queued: 0,
            inProgress: 0,
            complete: 0,
            failed: 0,
            interrupted: 0,
            results: 0,
            play: null,
            athentication: false,
            selectedFailureReason: null,
        };
    },
    components: {
        LoadingCheck,
        DangerAlert,
        TableLoading,
        SucessAlert
    },
    methods: {
        async getDetail() {
            try {
                const response = await axios.get(`/api/v1/campaigns/${this.campaignId}`, {
                    headers: {
                        'Authorization': `Bearer ${this.token}`,
                    }
                })
                this.campaignData = response.data.campaign;
                this.totalTasks = response.data.total_tasks;
                this.queued = response.data.queued;
                this.inProgress = response.data.in_progress;
                this.complete = response.data.complete;
                this.failed = response.data.failed;
                this.interrupted = response.data.interrupted;
                this.results = response.data.results
                this.isLoading = false
                this.athentication = true
                console.log(response)
            } catch (error) {
                if (error.response) {
                    const statusCode = error.response.status;
                    if (statusCode === 404) {
                        this.message = "Campaign not found"
                        this.$router.push({ name: 'Home' });
                        return

                    } else if (statusCode === 400) {

                        this.message = "Bad request (400)"

                    } else if (statusCode === 500) {

                        this.message = "Server error (500)"
                    }
                    else if (statusCode === 401) {
                        this.$router.push({ name: 'Login' });
                    }
                    else {
                        this.message = `Error: ${statusCode}`
                    }
                }
                else if (error.request) {
                    this.message = "No response received from server"

                } else {
                    this.message = `Request setup error ${error.message}`
                }
                this.isLoading = false
                this.danger = true
                this.sucess = false
                this.athentication = true

            }
            this.encodeId = encode(this.campaignId);
        },
        showFailureReasonMessage(msg) {
            this.selectedFailureReason = msg;
        },
        hideFailureReasonMessage() {
            this.selectedFailureReason = null;
        },
        async hideFailedUrls() {
            this.failedTable = false;
        },
        async getFailedUrls() {
            this.isSecondTable = true;
            this.failedTable = true;
            try {
                const response = await axios.get(`/api/v1/campaigns/${this.campaignId}/final_tasks`, {
                    headers: {
                        'Authorization': `Bearer ${this.token}`,
                    }
                })
                console.log(response)
                this.failedUrls = response.data
                this.isSecondTable = false
            } catch (error) {

                if (error.response) {
                    const statusCode = error.response.status;
                    if (statusCode === 404) {
                        this.message = "No urls found (404)"
                    } else if (statusCode === 400) {
                        this.message = "Bad request (400)"
                    } else if (statusCode === 500) {
                        this.message = "Server error (500)"
                    }
                    else if (statusCode === 401) {
                        this.$router.push({ name: 'Login' });
                    }
                    else {
                        this.message = `Error: ${statusCode}`
                    }
                }
                else if (error.request) {
                    this.message = "No response received from server"
                } else {
                    this.message = `Request setup error ${error.message}`
                }
                this.danger = true
                this.sucess = false
            }
        },
        async interruptCampaign() {
            try {
                const confirmed = confirm(`You are going to interrupt campaign ${this.campaignData.name}`)
                if (!confirmed) {
                    return;
                }
            } catch(e) {
                console.log(`Cannot interrupt campaign. Error: ${e.message}`);
                return;
            }
            this.isInterupt = true
            try {
                const response = await axios.get(`/api/v1/campaigns/${this.campaignId}/interrupt`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`
                    }
                })
                console.log(response)
                this.message = `Campaign with id ${this.campaignId} is Updated To Interrupted`
                this.sucess = true
                this.danger = false
                this.isInterupt = false
                this.getDetail()

            } catch (error) {
                if (error.response) {
                    const statusCode = error.response.status;
                    if (statusCode === 404) {
                        this.message = "Campaign not found (404)"
                    } else if (statusCode === 400) {
                        this.message = "Bad request (400)"

                    } else if (statusCode === 500) {
                        this.message = "Server error (500)"
                    }
                    else if (statusCode === 401) {
                        this.$router.push({ name: 'Login' });
                    }
                    else {
                        this.message = `Error: ${statusCode}`
                    }
                } else if (error.request) {
                    this.message = "No response received from server"

                } else {
                    this.message = `Request setup error ${error.message}`
                }
                this.danger = true
                this.sucess = false
                this.isInterupt = false
            }
        },
        async downloadResult() {
            this.isInterrupt = true
            try {
                const response = await axios.get(`/api/v1/campaigns/${this.campaignId}/results.csv`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`
                    },
                    responseType: 'blob'
                });
                const blob = new Blob([response.data], { type: 'text/csv' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `campaign_${this.campaignData.name}_data.csv`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                this.message = 'File downloaded successfully';
                this.sucess = true;
                this.danger = false;
                this.isInterupt = false


            } catch (error) {
                if (error.response) {
                    const statusCode = error.response.status;
                    if (statusCode === 404) {
                        this.message = "Campaign not found (404)"
                    } else if (statusCode === 400) {
                        this.message = "Bad request (400)"

                    } else if (statusCode === 500) {
                        this.message = "Server error (500)"
                    }
                    else if (statusCode === 401) {
                        this.$router.push({ name: 'Login' });
                    }
                    else {
                        this.message = `Error: ${statusCode}`
                    }
                } else if (error.request) {
                    this.message = "No response received from server"

                } else {
                    this.message = `Request setup error ${error.message}`
                }
                this.danger = true
                this.sucess = false
                this.isInterupt = false
            }
        },
        async restartFailedUrls() {
            this.isInterupt = true
            try {
                await axios.get(`/api/v1/campaigns/${this.campaignId}/restart_failed_tasks`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`
                    }
                })
                window.location.reload()
            } catch (error) {
                if (error.response) {
                    const statusCode = error.response.status;
                    if (statusCode === 404) {
                        this.message = error.response.detail
                    } else if (statusCode === 400) {
                        this.message = "Bad request (400)"

                    } else if (statusCode === 500) {
                        this.message = "Server error (500)"
                    }
                    else if (statusCode === 401) {
                        this.$router.push({ name: 'Login' });
                    }
                    else {
                        this.message = `Error: ${statusCode}`
                    }
                } else if (error.request) {
                    this.message = "No response received from server"

                } else {
                    this.message = `Request setup error ${error.message}`
                }
                this.danger = true
                this.sucess = false
                this.isInterupt = false
            }
        },
        async markAsComplete(url_id) {
            this.isInterupt = true
            var obj = {
                delete: false
            }
            try {
                const response = await axios.patch(`/api/v1/campaigns/update/task/${url_id}`, obj, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`
                    },

                });
                this.message = `Url with id ${response.data.id} is Updated To ${response.data.status}`
                this.sucess = true
                this.danger = false
                await this.getFailedUrls()
                this.isInterupt = false
            } catch (error) {

                if (error.response) {
                    const statusCode = error.response.status;
                    if (statusCode === 404) {
                        this.message = "Url not found (404)"
                    } else if (statusCode === 400) {
                        this.message = "Bad request (400)"

                    } else if (statusCode === 500) {
                        this.message = "Server error (500)"
                    }
                    else if (statusCode === 401) {
                        this.$router.push({ name: 'Login' });
                    }
                    else {
                        this.message = `Error: ${statusCode}`
                    }
                } else if (error.request) {
                    this.message = "No response received from server"

                } else {
                    this.message = `Request setup error ${error.message}`
                }
                this.danger = true
                this.sucess = false
                this.isInterupt = false
            }

        },
        async deleteUrl(url_id) {
            this.isInterupt = true
            var obj = {
                delete: true
            }
            try {
                await axios.patch(`/api/v1/campaigns/update/task/${url_id}`, obj, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`
                    },

                });

                await this.getFailedUrls()
                this.isInterupt = false

            } catch (error) {

                if (error.response) {
                    const statusCode = error.response.status;
                    if (statusCode === 404) {
                        this.message = "Url not found (404)"
                    } else if (statusCode === 400) {
                        this.message = "Bad request (400)"

                    } else if (statusCode === 500) {
                        this.message = "Server error (500)"
                    } else if (statusCode === 401) {
                        this.$router.push({ name: 'Login' });
                    } else {
                        this.message = `Error: ${statusCode}`
                    }
                } else if (error.request) {
                    this.message = "No response received from server"

                } else {
                    this.message = `Request setup error ${error.message}`
                }
                this.danger = true
                this.sucess = false
                this.isInterupt = false
            }
        },
        statusPlayPause(interrupt = false) {
            let update_status = {}
            if (interrupt === true) {
                update_status = {
                    status: "INTERRUPTED",
                    complete: false
                }
                return update_status
            }
            else if (this.campaignData.status === "PENDING" || this.campaignData.status === "PAUSED") {
                update_status = {
                    status: "IN_PROGRESS",
                    complete: false
                }
                return update_status
            }
            else if (this.campaignData.status === "IN_PROGRESS") {
                update_status = {
                    status: "PAUSED",
                    complete: false
                }
                return update_status
            }
        },
        async updatePlayPause() {
            this.isInterupt = true
            let update_status = this.statusPlayPause()
            try {
                await axios.patch(`/api/v1/campaigns/update/${this.campaignId}`, update_status, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`
                    }
                })

                window.location.reload()
            } catch (error) {
                if (error.response) {
                    const statusCode = error.response.status;
                    if (statusCode === 404) {
                        this.message = "Campaign not found (404)"
                    } else if (statusCode === 400) {
                        this.message = "Bad request (400)"

                    } else if (statusCode === 500) {
                        this.message = "Server error (500)"
                    }
                    else if (statusCode === 401) {
                        this.$router.push({ name: 'Login' });
                    } else {
                        this.message = `Error: ${statusCode}`
                    }
                } else if (error.request) {
                    this.message = "No response received from server"

                } else {
                    this.message = `Request setup error ${error.message}`
                }
            }
            this.isInterupt = false

        },
        async deleteCampaign() {
            this.isInterupt = true
            try {
                await axios.delete(`/api/v1/campaigns/delete/campaign/${this.campaignId}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`
                    }
                })
                this.isInterupt = false
                this.$router.push({ name: 'Home' });
            } catch (error) {
                if (error.response) {
                    const statusCode = error.response.status;
                    if (statusCode === 404) {
                        this.message = "Campaign not found (404)"
                    } else if (statusCode === 400) {
                        this.message = "Bad request (400)"

                    } else if (statusCode === 500) {
                        this.message = "Server error (500)"
                    }
                    else if (statusCode === 401) {
                        this.$router.push({ name: 'Login' });
                    }
                    else {
                        this.message = `Error: ${statusCode}`
                    }
                } else if (error.request) {
                    this.message = "No response received from server"

                } else {
                    this.message = `Request setup error ${error.message}`
                }
                this.isInterupt = false
            }
        },
        UpdateDataLocalstorage() {
            const campaignData = {
                name: "Cpg Name",
                status: "PENDING",
                start_by: "Manually",
                start_at: "2024-10-15 10:00",
                scraping_schedule_type: "PART_TIME",
                scraping_schedules: [
                    {
                        week_days: ["Sun", "Mon", "Tue"],
                        time_start: "10:00",
                        time_end: "16:00"
                    }
                ]
            };

            // Convert the object to a string and store it in localStorage
            localStorage.setItem('campaignData', JSON.stringify(campaignData));
        },
        closeAlert() {
            this.danger = false
        },
        closeSucess() {
            this.sucess = false
        }
    },

    async mounted() {
        try {
            this.token = JSON.parse(localStorage.getItem('token'))

        } catch (error) {
            this.$router.push({ name: 'Login' });
            return
        }
        if (this.token === null) {
            this.$router.push({ name: 'Login' });
            return
        }
        this.campaignId = decode(this.$route.query.id)
        console.log(`Decoded campaign ID: ${this.campaignId}`)
        if (this.campaignId === undefined) {
            this.$router.push({ name: 'Home' });
            return
        }
        await this.getDetail();
    }
}
</script>