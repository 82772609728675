<template>
    <div class="Page-outline">
        <div class="d-flex flex-column align-items-center justify-content-center min-vh-100 w-100">
            <DangerAlert v-if="isDanger == true" :close="closeAlert" :message="alertMsg" />
            <SucessAlert v-if="isSucess == true" :close="closeSucess" :message="alertMsg" />
            <div class="shadow-lg d-flex min-vh-50 h-auto w-25 form-div ">
                <form class="d-flex align-items-center p-3 flex-column w-100">
                    <h2 class="pt-2 mb-3">Log in</h2>
                    <div class="form-group w-100 mt-2">
                        <label for="email">Email address</label>
                        <input type="text" v-model="email" class="form-control shadow" id="exampleInputEmail1"
                            aria-describedby="emailHelp" placeholder="Enter email" autocomplete="email" name="email">
                    </div>
                    <div class="form-group w-100 mt-2">
                        <label for="password">Password</label>
                        <input type="password" v-model="password" class="form-control shadow" id="exampleInputPassword1"
                            placeholder="Password" autocomplete="current-password" name="password">
                    </div>
                    <div class="d-flex w-100 ml-1">

                        <a class="form-check-label">Forget Password?</a>
                    </div>
                    <button type="button" class="btn btn-primary btn-block mt-3" @click="loginUser"
                        id="login-btn">Login</button>
                    <div class="form-check mt-3">
                        <router-link to='/signup' class="form-check-label">Not a member?</router-link>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <LoadingCheck v-if="isLoading == true" />
</template>


<script>
import LoadingCheck from "./Loading.vue"
import DangerAlert from "./Danger.vue";
import SucessAlert from "./Sucess.vue"
import axios from 'axios';


export default {
    name: 'UserLogin',
    components: {
        LoadingCheck,
        DangerAlert,
        SucessAlert
    },
    data() {
        return {
            email: '',
            password: '',
            isLoading: false,
            isDanger: false,
            isSucess: false,
            alertMsg: ''
        }
    },
    methods: {
        async loginUser() {
            var field = this.login()
            if (field === false) {
                return
            }
            const data = new URLSearchParams();
            data.append('username', this.email);
            data.append('password', this.password);
            this.isLoading = true

            try {

                const response = await axios.post('/api/v1/users/login', data, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': '*/*'
                    }
                });
                localStorage.setItem('token', JSON.stringify(response.data.access_token));
                this.isLoading = false
                this.$router.push({ name: 'Home' });

            } catch (error) {
                if (error.response) {
                    const statusCode = error.response.status;
                    if (statusCode === 404) {
                        this.alertMsg = "User not found (404)"
                    } else if (statusCode === 400) {
                        this.alertMsg = "Bad request (400)"

                    } else if (statusCode === 500) {
                        this.alertMsg = "Server error (500)"
                    } else if(statusCode === 401){
                        this.alertMsg = "User not found (401)"
                    } else {
                        this.alertMsg = `Error: ${statusCode}`
                    }
                } else if (error.request) {
                    this.alertMsg = "No response received from server"

                } else {
                    this.alertMsg = `Request setup error ${error.message}`
                }
 
                this.isDanger = true
                this.isLoading = false
            }
        },
        login() {
            this.isDanger = false
            if (this.email === '' || this.password === '') {
                this.isDanger = true
                this.alertMsg = 'All Field Are Required'
                return false
            }
            else {
                this.isLoading = true
                return true
            }
        },
        closeAlert() {
            this.isDanger = false
        },
        closeSucess() {
            this.isSucess = false
        }

    },
}
</script>